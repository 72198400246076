import Model from '@/shared/classes/model'
import { ITranslatable } from '@/shared/interfaces/classes/model.interfaces'
import Project from '@/shared/modules/project/models/project.model'
import IGroupResponse from '@/shared/modules/group/group-response.interface'
import IModelResponse from '@/shared/interfaces/modules/model-response.interface'
import Checklist from '@/shared/modules/checklist/checklist.model'

export enum GroupLevel {
  category = 'level-category',
  speciality = 'level-specialty',
  group = 'level-group',
  checklist = 'level-checklist'
}

export enum GroupType {
  documents = 'documents',
  checklists = 'checklists',
}

export default class Group extends Model<IModelResponse> {
  name!: ITranslatable<string>|string
  parentUuid!: string
  projectUuid!: string
  index!: number
  other!: boolean
  level!: string|null
  allowedLevel!: string|null

  droppable: boolean = true
  open: boolean = false

  parent!: Group
  project!: Project
  children!: Group[]
  checklist!: Checklist

  transform(data: IGroupResponse) {
    super.transform(data)

    this.name = data.name
    this.parentUuid = data.parent_uuid
    this.projectUuid = data.project_uuid
    this.index = data.index
    this.other = data.other
    this.level = data.level
    this.allowedLevel = data.allowed_level

    this.parent = this.setRelation(Group, data.parent)
    this.project = this.setRelation(Project, data.project)
    this.children = this.setRelation(Group, data.children)
    this.checklist = this.setRelation(Checklist, data.checklist)
  }
}
