



















import { Component, Prop, Vue } from 'vue-property-decorator'
import PageHeader from '@/admin/components/PageHeader.vue'
import Form from '@/shared/components/form/Form.vue'
import __ from '@/shared/helpers/__'
import FormBase from '@/shared/classes/form/form-base'
import { GlobalActions } from '@/shared/store/global/global.actions'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import { HttpMethod } from '@/shared/helpers/requests.helper'
import Violation from '@/shared/modules/violation/violation.model'
import FormGroup from '@/shared/classes/form/group'
import Field, { FieldSizes } from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import SelectField from '@/shared/classes/form/fields/Select/select-field'
import SearchableField from '@/shared/classes/form/fields/Select/searchable-field'
import IViolationResponse from '@/shared/modules/violation/violation-response.interface'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import { RoleLevel } from '@/shared/modules/role/role.model'
import User from '@/shared/modules/user/user.model'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import ListViolationAttachments from '@/company/components/violations/ListViolationAttachments.vue'
import { validateFields } from "@/shared/helpers/validate";

@Component({
  components: { Form, PageHeader },
  methods: { __ }
})
export default class EditViolationDialog extends Vue {
  @Prop() meta!: any

  static GROUP_DETAILS = 'details'
  static GROUP_ATTACHMENTS = 'attachments'

  form: FormBase = new FormBase()
    .setUuid(this.meta.violation.uuid)
    .setEndpoint(`company/${ this.company.slug }/violations`)
    .setMethod(HttpMethod.PUT)
    .setModel(Violation)
    .setTranslatable(true)
    .setGroups([
      new FormGroup()
        .setKey(EditViolationDialog.GROUP_DETAILS),
      new FormGroup()
        .setKey(EditViolationDialog.GROUP_ATTACHMENTS)
        .setComponent(ListViolationAttachments)
    ])
    .setFields([
      new SearchableField()
        .setKey('project_uuid')
        .setEntryKey('projectUuid')
        .setTitle(__('company.components.tasks.add-task-dialog.form.project'))
        .setDisabled(!! this.meta.project)
        .setGroup(EditViolationDialog.GROUP_DETAILS)
        .loadItems({ endpoint: `company/${ this.company.slug }/projects`, value: 'uuid', title: 'name', perPage: 20 })
        .setRequired(!this.meta.isFromHSE),
      new Field()
        .setKey('name')
        .setTranslatable(true)
        .setTitle(__('company.components.violations.edit-violation-dialog.form.name'))
        .setGroup(EditViolationDialog.GROUP_DETAILS)
        .isRequired(),
      new Field()
        .setType(FieldTypes.textArea)
        .setKey('description')
        .setTranslatable(true)
        .setTitle(__('company.components.violations.edit-violation-dialog.form.description'))
        .setGroup(EditViolationDialog.GROUP_DETAILS),
      new Field()
        .setType(FieldTypes.datePicker)
        .setKey('seen_at')
        .setEntryKey('seenAt')
        .setTitle(__('company.components.violations.edit-violation-dialog.form.seen-at'))
        .setSize(FieldSizes.half)
        .setGroup(EditViolationDialog.GROUP_DETAILS),
      new Field()
        .setType(FieldTypes.datePicker)
        .setKey('deadline')
        .setTitle(__('company.components.violations.edit-violation-dialog.form.deadline'))
        .setSize(FieldSizes.half)
        .setGroup(EditViolationDialog.GROUP_DETAILS),
      new SelectField()
        .setKey('type_uuid')
        .setEntryKey('typeUuid')
        .setTitle(__('company.components.violations.edit-violation-dialog.form.type'))
        .loadItems({ endpoint: 'violation-types', value: 'uuid', title: 'name' })
        .setDisabled(!!this.meta.isFromHSE)
        .setSize(FieldSizes.half)
        .setGroup(EditViolationDialog.GROUP_DETAILS)
        .setOnChange((value: string) => {
          const projectField = this.form.fields.find(field => field.key === 'project_uuid');
          // Project is not required if 'Health, safety & environment' is selected
          if (value === 'ds') projectField?.setRequired(false);
          else projectField?.setRequired(true);
        })
        .isRequired(),
      new SelectField()
        .setKey('status_uuid')
        .setEntryKey('statusUuid')
        .setTitle(__('company.components.violations.edit-violation-dialog.form.status'))
        .loadItems({ endpoint: `statuses/model/${ Violation.ALIAS }`, value: 'uuid', title: 'name', perPage: 20 })
        .setSize(FieldSizes.half)
        .setGroup(EditViolationDialog.GROUP_DETAILS)
        .isRequired(),
      new SearchableField()
        .setKey('responsible_uuid')
        .setEntryKey('responsibleUuid')
        .setTitle(__('company.components.violations.edit-violation-dialog.form.responsible'))
        .setDisabled(this.user.role.level === RoleLevel.employee)
        .loadItems({ endpoint: `company/${ this.company.slug }/employees`, value: 'uuid', title: 'full_name', perPage: 20 })
        .setSize(FieldSizes.half)
        .setGroup(EditViolationDialog.GROUP_DETAILS),
      new SearchableField()
        .setKey('responsible_employee_uuid')
        .setEntryKey('responsibleEmployeeUuid')
        .setTitle(__('company.components.violations.add-violation-dialog.form.responsible-employee'))
        .setDisabled(this.user.role.level === RoleLevel.employee)
        .loadItems({ endpoint: `company/${ this.company.slug }/employees`, value: 'uuid', title: 'full_name', perPage: 20 })
        .setSize(FieldSizes.half)
        .setGroup(EditViolationDialog.GROUP_DETAILS),
      new Field()
        .setKey('location')
        .setTitle(__('company.components.violations.edit-violation-dialog.form.location'))
        .setTranslatable(true)
        .setSize(FieldSizes.half)
        .setGroup(EditViolationDialog.GROUP_DETAILS),
      new Field()
        .setKey('solution')
        .setTitle(__('company.components.violations.edit-violation-dialog.form.solution'))
        .setTranslatable(true)
        .setSize(FieldSizes.half)
        .setGroup(EditViolationDialog.GROUP_DETAILS),
    ])
    .setOnEntryLoaded((entry: Violation) => {
      if (entry.typeUuid === 'ds') {
        const projectField = this.form.fields.find(field => field.key === 'project_uuid');
        projectField?.setRequired(false)
      }
    })
    .setValidate((data) => {
      const errors = validateFields(data, this.form?.fields, { onError: this.onError, onlyRegionLangRequired: true });
      if (errors) {
        this.form?.setErrors(errors);
        return false;
      }

      return true;
    })
    .setSubmit({
      text: __('company.components.violations.edit-violation-dialog.form.submit')
    })
    .setOnSuccess((response: IViolationResponse, entry: Violation) => {
      this.$store.dispatch(GlobalActions.showSnackBar, {
        type: SnackBarTypes.success,
        message: __('company.components.violations.edit-violation-dialog.form.on-success')
      })

      this.$store.dispatch(GlobalActions.closeDialog)
      this.meta.onSuccess && this.meta.onSuccess(entry)
    })

  onError() {
    this.$store.dispatch(GlobalActions.showSnackBar, {
      type: SnackBarTypes.error,
      message: __("validation.fill-all-field"),
    });
  }

  async closeDialog() {
    await this.$store.dispatch(GlobalActions.closeDialog)
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }

  get user(): User {
    return this.$store.getters[AuthGetters.getUser]
  }
}
