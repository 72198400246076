import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/modules/model-response.interface'
import { ITranslatable } from '@/shared/interfaces/classes/model.interfaces'
import Checklist from '@/shared/modules/checklist/checklist.model'
import Attachment from '@/shared/modules/attachment/attachment.model'
import ICheckpointResponse from '@/shared/modules/checkpoint/checkpoint-response.interface'
import Status from '@/shared/modules/status/status.model'
import Violation from '@/shared/modules/violation/violation.model'
import User from '@/shared/modules/user/user.model';

export default class Checkpoint extends Model<IModelResponse> {
  static ALIAS = 'checkpoint'

  static STATUS_DEFAULT = 'checkpoint.default'
  static STATUS_OK = 'checkpoint.ok'
  static STATUS_VIOLATION = 'checkpoint.violation'
  static STATUS_IR = 'checkpoint.ir'

  name!: ITranslatable<string>|string
  description!: ITranslatable<string>|string
  endAt!: string | null
  attachmentsCount!: number
  checklistUuid!: string
  statusUuid!: string
  violationUuid!: string
  controlUserUuid!: string

  checklist!: Checklist
  status!: Status
  attachments!: Attachment[]
  violation!: Violation
  controlUser!: User

  transform(data: ICheckpointResponse) {
    super.transform(data)

    this.name = data.name
    this.description = data.description
    this.endAt = data.end_at
    this.attachmentsCount = data.attachments_count
    this.checklistUuid = data.checklist_uuid
    this.statusUuid = data.status_uuid
    this.violationUuid = data.violation_uuid
    this.controlUserUuid = data.control_user_uuid

    this.checklist = this.setRelation(Checklist, data.checklist)
    this.status = this.setRelation(Status, data.status)
    this.attachments = this.setRelation(Checklist, data.attachments)
    this.violation = this.setRelation(Violation, data.violation)
    this.controlUser = this.setRelation(User, data.control_user)
  }
}
