import IModelResponse from '@/shared/interfaces/modules/model-response.interface'
import Model from '@/shared/classes/model'
import IAttachmentResponse from '@/shared/modules/attachment/attachment-response.interface'
import Group from '@/shared/modules/group/group.model'

export default class Attachment extends Model<IModelResponse> {
  name!: string
  description!: string
  url!: string
  imgUrl!: string | null
  groupUuid!: string
  customProperties!: any[]
  manipulations!: any
  mimeType!: string
  data!: any

  group!: Group

  transform(data: IAttachmentResponse) {
    super.transform(data)

    this.name = data.name
    this.description = data.description
    this.url = data.url
    this.imgUrl = data.img_url
    this.groupUuid = data.group_uuid
    this.customProperties = data.custom_properties
    this.manipulations = data.manipulations
    this.mimeType = data.mime_type
    this.data = data.data

    this.group = this.setRelation(Group, data.group)
  }
}
