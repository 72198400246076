
























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import UploadViolationAttachments from '@/company/components/violations/UploadViolationAttachments.vue'
import __ from '@/shared/helpers/__'
import permissions from '@/shared/helpers/permissions.helper'
import FormBase from '@/shared/classes/form/form-base'
import can from '@/shared/helpers/can.helper'
import Attachment from '@/shared/modules/attachment/attachment.model'
import { GlobalActions } from '@/shared/store/global/global.actions'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import ViolationService from '@/shared/modules/violation/violation.service'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import DataTableAction from '@/shared/classes/components/data-table/data-table-action'
import GroupService from '@/shared/modules/group/group.service'
import { downloadFile } from '@/shared/helpers/download-file.helper'
import DeleteAction from '@/shared/classes/components/data-table/default-actions/delete-action'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import AttachmentsPreview from '@/company/components/attachments/AttachmentsPreview.vue'

@Component({
  components: {
    AttachmentsPreview,
    UploadViolationAttachments,
    DataTable
  },
  methods: { __, can }
})
export default class ListViolationAttachments extends Vue {
  @Prop() form!: FormBase
  attachments: Attachment[] = []
  dialog: boolean = false
  permissions = permissions
  table: DataTableBase|null = null

  async created() {
    this.table = new DataTableBase()
      .setModel(Attachment)
      .setEndpoint(`company/${ this.company.slug }/violations/${ this.form.entry.uuid }/attachments`)
      .setHasFilter(false)
      .setUpdateUrl(false)
      .setHeaders([
        new DataTableHeader()
          .setKey('name')
          .setText(__('company.components.violations.edit-violation-attachments.table.headers.name')),
        new DataTableHeader()
          .setKey('customProperties.description')
          .setText(__('company.components.violations.edit-violation-attachments.table.headers.description'))
          .setSortable(false),
        new DataTableHeader()
          .setKey('customProperties.author.full_name')
          .setText(__('company.components.violations.edit-violation-attachments.table.headers.author')),
        new DataTableHeader()
          .setKey('createdAt')
          .setText(__('company.components.violations.edit-violation-attachments.table.headers.uploaded-date'))
      ])
      .setOnChange((items: Attachment[]) => this.attachments = items)
      .setActions([
        new DataTableAction()
          .setIcon('mdi-eye')
          .setAction((attachment: Attachment) => {
            const tab: any = window.open(attachment.url, '_blank')
            tab.focus()
          })
          .setTitle(__('company.components.violations.edit-violation-attachments.show')),
        new DataTableAction()
          .setIcon('mdi-download')
          .setAction((attachment: Attachment) => {
            GroupService.downloadAttachment(this.company, this.form.entry.projectUuid, attachment.groupUuid, attachment)
              .then((response: any) => downloadFile(response, attachment.name))
          })
          .setTitle(__('company.components.violations.edit-violation-attachments.download')),
        new DeleteAction()
          .setPermissions([permissions.company.violations.deleteAttachment])
          .setAction((attachment: Attachment) => {
            ViolationService.deleteAttachment(this.company, this.form.entry, attachment)
              .then(() => {
                this.table && this.table.removeItem(attachment)
                this.$store.dispatch(GlobalActions.showSnackBar, {
                  type: SnackBarTypes.success,
                  message: __('company.components.violations.edit-violation-attachments.delete', { name: attachment.name }),
                })
              })
          })
      ])
  }

  async onUploadSuccess() {
    const table: any = this.$refs.table
    await table.refresh()
    this.dialog = false
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
