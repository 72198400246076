










import { Component, Prop, Vue } from 'vue-property-decorator'
import PageHeader from '@/admin/components/PageHeader.vue'
import __ from '@/shared/helpers/__'
import Form from '@/shared/components/form/Form.vue'
import Violation from '@/shared/modules/violation/violation.model'
import FormBase from '@/shared/classes/form/form-base'
import Field from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'

@Component({
  components: { Form, PageHeader },
  methods: { __ }
})
export default class UploadViolationAttachments extends Vue {
  @Prop() violation!: Violation
  @Prop() onSuccess!: () => void
  form: FormBase = new FormBase()
    .setFiles(true)
    .setModel(Violation)
    .setEndpoint(`company/${ this.company.slug }/violations/${ this.violation.uuid }/upload`)
    .setFields([
      new Field()
        .setType(FieldTypes.dropzone)
        .setKey('attachments')
        .setTitle(__('company.components.violations.add-violation-dialog.form.attachments.upload')),
      new Field()
        .setKey('description')
        .setTitle(__('company.components.violations.add-violation-dialog.form.attachments.comment')),
    ])
    .setSubmit({
      text: __('company.components.violations.upload-violation-attachments.form.submit')
    })
    .setOnSuccess(() => {
      this.$store.dispatch(GlobalActions.showSnackBar, {
        type: SnackBarTypes.success,
        message: __('company.components.violations.upload-violation-attachments.form.on-success')
      })

      this.onSuccess && this.onSuccess()
    })

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
