

















import { Component, Prop, Vue } from 'vue-property-decorator'
import Attachment from '@/shared/modules/attachment/attachment.model'
import { GlobalActions } from '@/shared/store/global/global.actions'

@Component
export default class AttachmentsPreview extends Vue {
  @Prop() attachments!: Attachment[]

  openGallery(index: number) {
    this.$store.dispatch(GlobalActions.showGallery, {
      show: true,
      attachments: this.attachments,
      initialSlideIndex: index
    })
  }
}
