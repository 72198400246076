import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/modules/model-response.interface'
import Status from '@/shared/modules/status/status.model'
import User from '@/shared/modules/user/user.model'
import Project from '@/shared/modules/project/models/project.model'
import IViolationResponse from '@/shared/modules/violation/violation-response.interface'
import ViolationType from '@/shared/modules/violation-type/violation-type.model'

export default class Violation extends Model<IModelResponse> {
  static ALIAS = 'violation'

  static STATUS_CLOSED = 'violation.closed'
  static STATUS_OPEN = 'violation.open'

  uniqueId!: string
  name!: string
  description!: string
  seenAt!: string
  deadline!: string
  statusUuid!: string
  responsibleUuid!: string
  responsibleEmployeeUuid!: string
  authorUuid!: string
  projectUuid!: string
  typeUuid!: string
  location!: string
  solution!: string

  status!: Status
  responsible!: User
  responsibleEmployee!: User
  author!: User
  project!: Project
  type!: ViolationType

  transform(data: IViolationResponse) {
    super.transform(data)

    this.uniqueId = data.unique_id
    this.name = data.name
    this.description = data.description
    this.seenAt = data.seen_at
    this.deadline = data.deadline
    this.statusUuid = data.status_uuid
    this.responsibleUuid = data.responsible_uuid
    this.responsibleEmployeeUuid = data.responsible_employee_uuid
    this.authorUuid = data.author_uuid
    this.projectUuid = data.project_uuid
    this.typeUuid = data.type_uuid
    this.location = data.location
    this.solution = data.solution

    this.status = this.setRelation(Status, data.status)
    this.responsible = this.setRelation(User, data.responsible)
    this.responsibleEmployee = this.setRelation(User, data.responsible_employee)
    this.author = this.setRelation(User, data.author)
    this.project = this.setRelation(Project, data.project)
    this.type = this.setRelation(ViolationType, data.type)
  }
}
