import Model from '@/shared/classes/model'
import { ITranslatable } from '@/shared/interfaces/classes/model.interfaces'
import IModelResponse from '@/shared/interfaces/modules/model-response.interface'
import IChecklistResponse from '@/shared/modules/checklist/checklist-response.interface'
import Group from '@/shared/modules/group/group.model'
import Checkpoint from '@/shared/modules/checkpoint/checkpoint.model'
import User from '@/shared/modules/user/user.model'

export default class Checklist extends Model<IModelResponse> {
  name!: ITranslatable<string>|string
  description!: ITranslatable<string>|string
  groupUuid!: string
  responsibleUuid!: string
  controlResponsibleUserUuid!: string
  controlResponsibleUserAssignedAt!: string

  group!: Group
  checkpoints!: Checkpoint[]
  responsible!: User
  controlResponsibleUser!: User

  transform(data: IChecklistResponse) {
    super.transform(data)

    this.name = data.name
    this.description = data.description
    this.groupUuid = data.group_uuid
    this.responsibleUuid = data.responsible_uuid
    this.controlResponsibleUserUuid = data.control_responsible_user_uuid
    this.controlResponsibleUserAssignedAt = data.control_responsible_user_assigned_at

    this.group = this.setRelation(Group, data.group)
    this.checkpoints = this.setRelation(Checkpoint, data.checkpoints)
    this.responsible = this.setRelation(User, data.responsible)
    this.controlResponsibleUser = this.setRelation(User, data.control_responsible_user)
  }
}
