import Service from '@/shared/classes/service'
import Company from '@/shared/modules/company/company.model'
import Project from '@/shared/modules/project/models/project.model'
import IViolationResponse from '@/shared/modules/violation/violation-response.interface'
import IResponse from '@/shared/interfaces/modules/response.interface'
import Violation from '@/shared/modules/violation/violation.model'
import Attachment from '@/shared/modules/attachment/attachment.model'
import IAttachmentResponse from '@/shared/modules/attachment/attachment-response.interface'

export default class ViolationService extends Service {
  static prefix = 'violations'

  static uploadAttachments(company: Company, violationUuid: string, data: FormData): Promise<IViolationResponse> {
    return this.post<IViolationResponse>(`company/${ company.slug }/${ this.prefix }/${ violationUuid }/upload`, data)
      .then((response: IResponse<IViolationResponse>) => response.data)
  }

  static deleteAttachment(company: Company, violation: Violation, attachment: Attachment): Promise<any> {
    return this.delete(`company/${ company.slug }/${ this.prefix }/${ violation.uuid }/attachments/${ attachment.uuid }`)
  }

  static destroy(company: Company, violation: Violation): Promise<any> {
    return this.delete(`company/${ company.slug }/${ this.prefix }/${ violation.uuid }`)
  }

  static attachments(company: Company, violation: Violation): Promise<Attachment[]> {
    return this.get(`company/${ company.slug }/${ this.prefix }/${ violation.uuid }/attachments`)
      .then((response: IResponse<IAttachmentResponse>) => response.data.data.map((item: IAttachmentResponse) => new Attachment(item)))
  }
}
