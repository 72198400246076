import Service from '@/shared/classes/service'
import Group from '@/shared/modules/group/group.model'
import Company from '@/shared/modules/company/company.model'
import Project from '@/shared/modules/project/models/project.model'
import IResponse from '@/shared/interfaces/modules/response.interface'
import IGroupResponse from '@/shared/modules/group/group-response.interface'
import Attachment from '@/shared/modules/attachment/attachment.model'
import IAttachmentResponse from '@/shared/modules/attachment/attachment-response.interface'
import Checklist from '@/shared/modules/checklist/checklist.model'
import IChecklistResponse from '@/shared/modules/checklist/checklist-response.interface'

export default class GroupService extends Service {
  static prefix = 'groups'

  static tree(company: Company, project: Project, type: string = 'documents'): Promise<Group[]> {
    return this.get(`company/${ company.slug }/projects/${ project.uuid }/${ this.prefix }/tree?filter-equals-type=${ type }`)
      .then((response: IResponse<IGroupResponse[]>) => response.data.map((item: IGroupResponse) => new Group(item)))
  }

  static attachments(company: Company, project: Project, group: Group, page: number = 1): Promise<any> {
    return this.get(`company/${ company.slug}/projects/${ project.uuid }/${ this.prefix }/${ group.uuid }/attachments?page=${ page }`)
      .then((response: IResponse<IAttachmentResponse[]>) => ({
        data: response.data.data.map((item: IAttachmentResponse) => new Attachment(item)),
        meta: response.data.meta,
      }))
  }

  static destroy(company: Company, project: Project, group: Group): Promise<any> {
    return this.delete(`company/${ company.slug}/projects/${ project.uuid }/${ this.prefix }/${ group.uuid }`)
  }

  static downloadAttachment(company: Company, projectUuid: string, groupUuid: string, attachment: Attachment): Promise<any> {
    return this.get(
      `company/${ company.slug }/projects/${ projectUuid }/groups/${ groupUuid }/attachments/${ attachment.uuid }/download`,
      { responseType: 'blob' }
      )
  }

  static massDownloadAttachments(company: Company, project: Project, group: Group, attachments: string[]): Promise<any> {
    return this.post(
      `company/${ company.slug }/projects/${ project.uuid }/groups/${ group.uuid }/attachments/mass-download`,
      { attachment_uuids: attachments },
      { responseType: 'arraybuffer' }
    )
  }

  static massDeleteAttachments(company: Company, project: Project, group: Group, attachments: string[]): Promise<any> {
    return this.post(
      `company/${ company.slug }/projects/${ project.uuid }/groups/${ group.uuid }/attachments/mass-delete`,
      { attachment_uuids: attachments },
    )
  }

  static deleteAttachment(company: Company, project: Project, group: Group, attachment: Attachment): Promise<any> {
    return this.delete(`company/${ company.slug }/projects/${ project.uuid }/groups/${ group.uuid }/attachments/${ attachment.uuid }`)
  }

  static rotateAttachment(company: Company, project: Project, group: Group, attachment: Attachment, degrees: number): Promise<Attachment> {
    return this.post(
      `company/${ company.slug }/projects/${ project.uuid }/groups/${ group.uuid }/attachments/${ attachment.uuid }/rotate`,
      { degrees }
      ).then((response: IResponse<IAttachmentResponse>) => new Attachment(response.data))
  }

  static dragAndDrop(company: Company, project: Project, data: any): Promise<any> {
    return this.post(`company/${ company.slug }/projects/${ project.uuid }/groups/drag-and-drop`, { tree: data })
      .then((response: IResponse<any>) => response.data)
  }

  static checklist(company: Company, project: Project, group: Group): Promise<Checklist> {
    return this.get(`company/${ company.slug }/projects/${ project.uuid }/${ this.prefix }/${ group.uuid }/checklist`)
      .then((response: IResponse<IChecklistResponse>) => new Checklist(response.data))
  }

  static generatePdf(company: Company, projectUuid: string, group: Group, withViolations: boolean = false): Promise<any> {
    return this.get(`company/${ company.slug }/projects/${ projectUuid }/${ this.prefix }/${ group.uuid }/generate-pdf${ withViolations ? '?withViolations=true' : '' }`, { responseType: 'blob' })
  }

  static parentUuids(company: Company, projectUuid: string, group: Group): Promise<string[]> {
    return this.get(`company/${ company.slug }/projects/${ projectUuid }/${ this.prefix }/${ group.uuid }/parent-uuids`)
      .then((response: IResponse<string[]>) => response.data)
  }
}
