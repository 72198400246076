import Model from '@/shared/classes/model'
import { ITranslatable } from '@/shared/interfaces/classes/model.interfaces'
import IModelResponse from '@/shared/interfaces/modules/model-response.interface'
import IViolationTypeResponse from '@/shared/modules/violation-type/violation-type-response.interface'

export default class ViolationType extends Model<IModelResponse> {
  name!: ITranslatable<string>|string

  transform(data: IViolationTypeResponse) {
    super.transform(data)

    this.name = data.name
  }
}
